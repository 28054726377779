import { doc, updateDoc } from "firebase/firestore";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { db } from "../../helpers/firebase";
import { getFCMToken } from "../user/user.selector";
import {
  fetchRecentFailure,
  fetchRecentSuccess,
  loadAdminFailure,
  loadAdminSuccess,
  loadDepositsFailure,
  loadDepositsSuccess,
  loadSettingSuccess,
  loadSwapsFailure,
  loadSwapsSuccess,
  loadTransactionsFailure,
  loadTransactionsSuccess,
  loadWhitelistsFailure,
  loadWhitelistsSuccess,
  loadWithdrawsFailure,
  loadWithdrawsSuccess,
  updateSettingFailure,
  updateSettingSuccess
} from "./setting.actions";
import SettingAPI from "./setting.api";
import { SettingActionTypes } from "./setting.model";

export function* handleFetchAdminSetting() {
  try {
    const response = yield call(SettingAPI.LoadAdminSetting);
    yield put(loadAdminSuccess(response));
    const token = yield select(getFCMToken);
    debugger;
    updateDoc(doc(db, "admin", response.id), {
      fcmToken: token,
    }).then((d) => {
      console.log(d);
    });
  } catch (e) {
    yield put(loadAdminFailure(e));
  }
}

export function* handleFetchSetting() {
  try {
    const response = yield call(SettingAPI.LoadSetting);
    yield put(loadSettingSuccess(response));
  } catch (e) {
    yield put(loadAdminSuccess(e));
  }
}

export function* handleUpdateSetting(action) {
  try {
    const response = yield call(SettingAPI.UpdateSetting, action.payload);
    yield put(updateSettingSuccess(response));
  } catch (e) {
    yield put(updateSettingFailure(e));
  }
}

export function* handleFetchTransactions(action) {
  try {
    const response = yield call(SettingAPI.FetchTransactions, action.payload);
    yield put(loadTransactionsSuccess(response));
  } catch (e) {
    yield put(loadTransactionsFailure(e));
  }
}

export function* handleFetchDeposits(action) {
  try {
    const response = yield call(SettingAPI.FetchDeposits, action.payload);
    yield put(loadDepositsSuccess(response));
  } catch (e) {
    yield put(loadDepositsFailure(e));
  }
}

export function* handleFetchWithdraws(action) {
  try {
    const response = yield call(SettingAPI.FetchWithdraws, action.payload);
    yield put(loadWithdrawsSuccess(response));
  } catch (e) {
    yield put(loadWithdrawsFailure(e));
  }
}

export function* handleFetchWhitelists(action) {
  try {
    const response = yield call(SettingAPI.FetchWhitelists, action.payload);
    yield put(loadWhitelistsSuccess(response));
  } catch (e) {
    yield put(loadWhitelistsFailure(e));
  }
}

export function* handleSwaps(action) {
  try {
    const response = yield call(SettingAPI.FetchSwaps, action.payload);
    yield put(loadSwapsSuccess(response));
  } catch (e) {
    yield put(loadSwapsFailure(e));
  }
}

export function* handleRecents(action) {
  try {
    const response = yield call(
      SettingAPI.LoadRecentTransactions,
      action.payload
    );
    yield put(fetchRecentSuccess(response));
  } catch (e) {
    yield put(fetchRecentFailure(e));
  }
}

export function* SettingSaga() {
  yield takeEvery(
    SettingActionTypes.FETCH_ADMIN_REQUEST,
    handleFetchAdminSetting
  );
  yield takeEvery(SettingActionTypes.FETCH_SETTING_REQUEST, handleFetchSetting);
  yield takeEvery(
    SettingActionTypes.UPDATE_SETTING_REQUEST,
    handleUpdateSetting
  );
  yield takeEvery(
    SettingActionTypes.FETCH_TRANSACTIONS_REQUEST,
    handleFetchTransactions
  );
  yield takeEvery(
    SettingActionTypes.FETCH_DEPOSITS_REQUEST,
    handleFetchDeposits
  );
  yield takeEvery(
    SettingActionTypes.FETCH_WITHDRAWS_REQUEST,
    handleFetchWithdraws
  );
  yield takeEvery(
    SettingActionTypes.FETCH_WHITELISTS_REQUEST,
    handleFetchWhitelists
  );
  yield takeEvery(SettingActionTypes.FETCH_SWAPS_REQUEST, handleSwaps);
  yield takeEvery(SettingActionTypes.FETCH_RECENTS_REQUEST, handleRecents);
}
