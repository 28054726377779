import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./assets/css/toastr.min.css";
import { setupNotifications } from "./helpers/firebase";
import {
  sendNativeNotification,
  toastNotification,
} from "./helpers/notificationHelpers";
import ProtectedRoute from "./helpers/PrivateRoute";
import { IsNullOrEmpty } from "./helpers/utility";
import useVisibilityChange from "./pages/base/useVisibilityChange";
import DashboardContainer from "./pages/dashboard/Dashboard.Container";
import DepositContainer from "./pages/deposits/Deposit.Container";
import LoginContainer from "./pages/login/Login.Container";
import settingContainer from "./pages/settings/Setting.Container";
import SwapContainer from "./pages/swaps/Swap.Container";
import TransactionContainer from "./pages/transactions/Transaction.Container";
import UserContainer from "./pages/users/User.Container";
import WhitelistContainer from "./pages/whitelists/Whitelist.Container";
import WithdrawContainer from "./pages/withdraws/Withdraw.Container";
import theme from "./theme";

export default function App(props) {
  let token = sessionStorage.getItem("x-wallet-payment");
  const isForeground = useVisibilityChange();
  useEffect(() => {
    setupNotifications((message) => {
      debugger;
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: "title",
          description: "body",
          status: "info",
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: "title",
          body: "body",
        });
      }
    });
  }, []);
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <ProtectedRoute
            exact
            path="/customers"
            component={UserContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/"
            component={DashboardContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/transactions"
            component={TransactionContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/settings"
            component={settingContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/deposits"
            component={DepositContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/swaps"
            component={SwapContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/whitelists"
            component={WhitelistContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
          <ProtectedRoute
            exact
            path="/withdraws"
            component={WithdrawContainer}
            isAuthenticated={!IsNullOrEmpty(token)}
            isVerifying={false}
          />
        </Switch>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}
