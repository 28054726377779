import { action } from "typesafe-actions";
import { UserActionTypes } from "./user.model";

export const loadUsersRequest = (req) =>
  action(UserActionTypes.FETCH_REQUEST, req);
export const loadUsersSuccess = (res) =>
  action(UserActionTypes.FETCH_SUCCESS, res);
export const loadUsersFailure = (err) =>
  action(UserActionTypes.FETCH_ERROR, err);

export const loadUserDetailRequest = (req) =>
  action(UserActionTypes.DETAIL_REQUEST, req);
export const loadUserDetailSuccess = (res) =>
  action(UserActionTypes.DETAIL_SUCCESS, res);
export const loadUserDetailFailure = (err) =>
  action(UserActionTypes.FETCH_ERROR, err);

export const addUserRequest = (req) => action(UserActionTypes.ADD_REQUEST, req);
export const addUserSuccess = (res) => action(UserActionTypes.ADD_SUCCESS, res);
export const addUserFailure = (err) => action(UserActionTypes.FETCH_ERROR, err);

export const updateUserRequest = (req) =>
  action(UserActionTypes.UPDATE_REQUEST, req);
export const updateUserSuccess = (res) =>
  action(UserActionTypes.UPDATE_SUCCESS, res);
export const updateUserFailure = (err) =>
  action(UserActionTypes.FETCH_ERROR, err);

export const deleteUserRequest = (req) =>
  action(UserActionTypes.DELETE_REQUEST, req);
export const deleteUserSuccess = (res) =>
  action(UserActionTypes.DELETE_SUCCESS, res);
export const deleteUserFailure = (err) =>
  action(UserActionTypes.FETCH_ERROR, err);

export const selectUserRequest = (id) =>
  action(UserActionTypes.SELECT_USER, id);

export const setFCMToken = (token) => action(UserActionTypes.SET_FCM_TOKEN, token);
